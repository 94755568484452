import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../../components/layout/Index"
import ButtonBack from "../../../components/commons/page/buttonBack/index"
import { withTrans } from "../../../i18n/withTrans"

const Index = ({ t }) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    getMember()
  }, [])

  const getMember = () => {
    let data_url = typeof window !== "undefined" ? window.location.pathname : ""
    let url = data_url.split("/")
    let id = url[3]
    fetch(`${process.env.GATSBY_API_URL}/member?id=${id}`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Error")
        }
      })
      .then(result => {
        setData(result.data[0])
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Layout>
      <Container>
        <div className="d-flex align-items-center mb-4">
          <span className="page-title mb-0 mr-5">
            {t("activeMember.activeMemberDetail")}
          </span>
          <ButtonBack />
        </div>
        <Row className="border rounded">
          <Col
            md={3}
            style={{ padding: 0, background: "#00A650", borderRadius: 4 }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center px-3 py-4">
              <div class="detail-member-img-circle mt-3">
                <img
                  alt=""
                  src={`${
                    data?.member_profile_data?.logo_filename
                      ? `${process.env.GATSBY_API_URL}/file/logo/${data.member_profile_data.logo_filename}`
                      : "../../../images/Img_default.png"
                  }`}
                />
              </div>
              <div style={{ marginTop: 20, textAlign: "center" }}>
                <span style={{ fontSize: 20, fontWeight: 700, color: "#fff" }}>
                  {data?.name}
                </span>
              </div>
              {/* <div style={{ marginBottom: 20, textAlign: "center" }}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "#fff",
                  }}
                >
                  {data?.member_profile_data?.description}
                </span>
              </div> */}
            </div>
          </Col>
          <Col md={9} style={{ padding: 30, fontSize: 14, position: 'relative'}} className="align-self-center">
            <span style={{ fontWeight: 700, fontSize: 16 }}>Profile</span>

            {/* cr hide */}
            {/* <Row className="border-bottom py-3">
              <div className="col-sm-3">
                <span style={{ color: "rgba(73,80,87,.7)" }}>
                  {t("activeMember.ktaNumber")}
                </span>
              </div>
              <div className="col-sm-8">
                <span>{data?.member_profile_data?.kta_no}</span>
              </div>
            </Row> */}

            <div className="align-middle">
              <Row className="border-bottom py-3">
                <div className="col-sm-3">
                  <span style={{ color: "rgba(73,80,87,.7)" }}>
                    {t("activeMember.companyAddress")}
                  </span>
                </div>
                <div className="col-sm-8">
                  <span>
                    {data?.member_profile_data?.address
                      ? data.member_profile_data.address
                      : "-"}
                  </span>
                </div>
              </Row>
              <Row className="border-top py-3">
                <div className="col-sm-3">
                  <span style={{ color: "rgba(73,80,87,.7)" }}>
                    {t("activeMember.region")}
                  </span>
                </div>
                <div className="col-sm-8">
                  <span>
                    {data?.region_name
                      ? data.region_name
                      : "-"}
                  </span>
                </div>
              </Row>
            </div>

            {/* cr hide */}
            {/* <Row className="border-bottom py-3">
              <div className="col-sm-3">
                <span style={{ color: "rgba(73,80,87,.7)" }}>
                  {t("activeMember.pakPermits")}
                </span>
              </div>
              <div className="col-sm-8">
                <span>
                  {data?.member_profile_data?.pak
                    ? data.member_profile_data.pak
                    : "-"}
                </span>
              </div>
            </Row>
            <Row className="border-bottom py-3">
              <div className="col-sm-3">
                <span style={{ color: "rgba(73,80,87,.7)" }}>
                  {t("activeMember.leaderName")}
                </span>
              </div>
              <div className="col-sm-8">
                <span>
                  {data?.member_profile_data?.leader_name
                    ? data.member_profile_data.leader_name
                    : "-"}
                </span>
              </div>
            </Row>
            <Row className="border-bottom py-3">
              <div className="col-sm-3">
                <span style={{ color: "rgba(73,80,87,.7)" }}>
                  {t("activeMember.position")}
                </span>
              </div>
              <div className="col-sm-8">
                <span>
                  {data?.member_profile_data?.position
                    ? data.member_profile_data.position
                    : "-"}
                </span>
              </div>
            </Row>
            <Row className="border-bottom py-3">
              <div className="col-sm-3">
                <span style={{ color: "rgba(73,80,87,.7)" }}>
                  {t("activeMember.contactPerson")}
                </span>
              </div>
              <div className="col-sm-8">
                {data?.member_profile_data?.contact_person_name_1 &&
                data?.member_profile_data?.contact_person_email_1 &&
                data?.member_profile_data?.contact_person_phone_number_1 ? (
                  <>
                    <div>
                      <span>
                        {data?.member_profile_data?.contact_person_name_1
                          ? data.member_profile_data.contact_person_name_1
                          : "-"}
                      </span>
                    </div>
                    <div>
                      <span>
                        {data?.member_profile_data?.contact_person_email_1
                          ? data.member_profile_data.contact_person_email_1
                          : "-"}
                      </span>
                    </div>
                    <div>
                      <span>
                        {data?.member_profile_data
                          ?.contact_person_phone_number_1
                          ? data.member_profile_data
                              .contact_person_phone_number_1
                          : "-"}
                      </span>
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </div>
            </Row>
            <Row className="py-3">
              <div className="col-sm-3">
                <span style={{ color: "rgba(73,80,87,.7)" }}>
                  {t("activeMember.information")}
                </span>
              </div>
              <div className="col-sm-8">
                <span>
                  {data?.member_profile_data?.institutional
                    ? data.member_profile_data.institutional.replace(
                        /(^\w{1})|(\s+\w{1})/g,
                        letter => letter.toUpperCase()
                      )
                    : "-"}
                </span>
              </div>
            </Row> */}
          </Col>
        </Row>
        <Row md={12}>
          <div
            style={{
              width: "100%",
              height: "150px",
              marginTop: 50,
              color: "#fff",
              background:
                "transparent linear-gradient(90deg, #909090 0%, #b4b4b4 66%, #d7d7d7 100%) 0% 0% no-repeat padding-box",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src="../../../images/ic_logo_gakeslab.png" alt="" />
            <div className="d-flex flex-column m-3">
              <span style={{ fontSize: 20 }}>SILVER AD SPACE</span>
              <span>1056 X 150 PIXEL</span>
            </div>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default withTrans(Index)
